import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"

const PageContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
  }
`

const ProductContainer = styled.div`
  width: 100%;
  padding-left: 1.25em;
  padding-right: 1.25em;

  @media (min-width: ${breakpoints.md}) {
    width: 50%;
    padding-left: 2.25em;
    padding-right: 2.25em;
  }
`

const ProductHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 68px;
    justify-content: flex-start;
  }
`

const ProductHeading = styled.h3`
  padding-right: 32px;
`

const Purchase = styled.input`
  cursor: pointer;
  text-underline-position: under;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline-color: red;
    outline-style: auto;
    outline-width: 0.13em;
    outline-offset: 0.2em;
  }
`

const ProductImage = styled.img`

`

const ProductDescription = styled.div`
  text-align: left;
  padding-top: 2em;
  padding-bottom: 4em;
  font-family: "ITC Century W01 Book", serif;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 68px;
    padding-bottom: 8em;
  }

  a {
    text-underline-position: under;
  
    :hover {
      text-decoration: underline;
    }
  }
`

const StorePage = ({ location }) => {
  const path = location.pathname

  const pageTitle = "Store"

  const pageDescription = "Greg White photographer - Store. Represented by Picture Club. Award winning for his commercial and personal projects, specialising in landscape, technology and automotive photography."

  const products = [
    {
      title: "Chernobyl, 2013",
      image: "https://images.ctfassets.net/opicik414f1r/fF5972mJq58AxdqrtoMdT/d6975292663a41dca0c5e55326303753/gregwhite-chernobyl-storesq.jpg",
      description: (
        <>
          <p>
          "In April 1986, reactor No.4 of the Chernobyl nuclear power plant exploded. Helicopter pilots spent two weeks dumping thousands of tonnes of sand on the fire before it was finally extinguished. Soldiers then used wheelbarrows and shovels to collect radioactive rubble, which they tipped into the central heap. Finally construction crews arrived. During the six months proceeding the accident they built a steel and concrete containment structure over the reactor's remains. They nicknamed it the Sarcophagus. It was badly constructed. It has corroded. Now it could collapse."
          <br />
          This photography book studies two of the surrounding areas to the Chernobyl Nuclear power plant as well as the plant itself where a large scale engineering project is well under way.  Foreword by Andrew Hankinson and designed by <a href="http://eachlondon.com/">Each London</a> the book is section sewn and has a unique fold out cover with an inner cover as well, reflecting the protective objectives of the New Safe Confinement documented in the book.
          <br />
          Limited to 300 copies
          <br />
          72 Pages full colour
          <br />
          Published 2013 Greg White Photography Ltd
          <br />
          ISBN 978-0-9575703-0-6
          </p>
        </>
      ),
      purchase: (
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="9abhpq3d2anww" />
          <Purchase type="submit" name="submit" value="Purchase" />
        </form>
      )
    },
    {
      title: "Svålbard, 2010",
      image: "https://images.ctfassets.net/opicik414f1r/37dvqrWhlNpEGmawQhSJtg/f2d9b77f085d1bfeb163634997cb38e8/gregwhite-svalbard-storesq.jpg",
      description: (
        <>
          <p>
          "In the Svålbard archipelago, over 600 miles north of Norway, the sun doesn't set between April and August. From October until February, on the other hand, it doesn't appear at all. As one of the closest landmasses to the North Pole, it may seem an inhospitable climate for all but the most resilient of miners or fishermen. Near the northerly town of Longyearbyen on the island of Spitsbergen, however, the agricultural equivalent of Noah's Ark is nestled in the side of a snow covered mountain…"
          <br />
          The book is cleverly divided into two distinct sections: the first studies the landscape and inhabitants of this Arctic outpost, the second then explores the impressive Svalbard Global Seed Vault and more. The binding reflects the rough nature of the environment and inside uses a considered mix of semi-gloss and matt paper stocks, all brilliantly designed by <a href="http://eachlondon.com/">Each London</a>.
          <br />
          Limited to 500 copies
          <br />
          64 Pages full colour
          <br />
          Published 2010
          </p>
        </>
      ),
      purchase: (
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="zrzrgnckczuc6" />
          <Purchase type="submit" name="submit" value="Purchase" />
        </form>
      )
    }
  ]

  return (
    <Layout>
      <Seo fullTitle={false} path={path} title={pageTitle} description={pageDescription} />
      <PageContainer>
        {products.map((product, index) => {
          const {title, image, description, purchase} = product
          return (
            <ProductContainer key={index}>
              <ProductHeader>
                <ProductHeading>{title}</ProductHeading>
                {purchase}
              </ProductHeader>
              <ProductImage src={image} alt={title} />
              <ProductDescription>{description}</ProductDescription>
            </ProductContainer>
          )
        })}
      </PageContainer>
    </Layout>
  )
}

export default StorePage